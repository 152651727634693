@import (reference) "colors.less";
@import (reference) "screen-sizes.less";

.breadcrumbs {
  font-size: 16px;
  line-height: 24px;
  margin: 10px 0;

  .breadcrumb {
    display: inline-block;
  }

  i.icon-angle-right {
    color: @color-grayC5;
  }
  @media print {
    display: none;
  }
}
