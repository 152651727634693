@import (reference) "colors.less";

.content-result {
  .collectible-content-item {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 20px;
    padding: 8px 5px;
    text-align: right;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 10;

    .btn-round {
      padding: 0;
      width: 36px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      font-size: 20px;
      color: @color-dark-gray87;
      box-sizing: border-box;
      pointer-events: auto;
      cursor: pointer;

      &:hover {
        color: @color-dark-gray87;
      }

      .icon-bookmark {
        display: inline;
        margin-right: -1px;
      }

      .icon-bookmark-filled {
        display: none;
        color: @color-primary;
        margin-right: -1px;
      }

      &.selected {
        .icon-bookmark {
          display: none;
        }

        .icon-bookmark-filled {
          display: inline;
        }
      }
    }
  }

  &:hover {
    .collectible-content-item {
      opacity: 1;
    }
  }
}

.collectible-content-item.inline {
  font-weight: 600;

  .btn-collect {
    .icon-bookmark {
      display: inline;
      margin-right: -1px;
    }

    .icon-bookmark-filled {
      display: none;
      margin-right: -1px;
    }

    span.add-to-collection {
      display: inline;
    }

    span.added-to-collection {
      display: none;
    }

    &.selected {
      .icon-bookmark {
        display: none;
      }

      .icon-bookmark-filled {
        display: inline;
      }

      span.add-to-collection {
        display: none;
      }

      span.added-to-collection {
        display: inline;
      }
    }

    &:hover {
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }

  &.disabled {
    pointer-events: none;

    a {
      color: @color-gray9;
    }
  }
}

/**
  Critical styles necessary for the custom collections modals to prevent flash on load
 */
#collections {
  .choose-collection,
  .new-collection {
    transform: translateY(400px);
    opacity: 0;
    transition: transform 250ms, opacity 250ms, height 250ms, background 250ms;
  }

  .collection {
    transform: translateY(55px);
    opacity: 0;
    transition: transform 250ms, opacity 250ms;
  }

  .overlay-back {
    background: rgba(0,0,0,.25);
    opacity: 0;
    transition: opacity 250ms;
  }
}
