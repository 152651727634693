@font-face {
  font-family: 'TTNorms-Medium';
  font-display: fallback;
  src: url('/themes/sky/fonts/TTNorms/tt-norms-medium.eot');
  src: url('/themes/sky/fonts/TTNorms/tt-norms-medium.eot?#iefix') format('embedded-opentype'),
       url('/themes/sky/fonts/TTNorms/tt-norms-medium.woff2') format('woff2'),
       url('/themes/sky/fonts/TTNorms/tt-norms-medium.woff') format('woff'),
       url('/themes/sky/fonts/TTNorms/tt-norms-medium.ttf') format('truetype'),
       url('/themes/sky/fonts/TTNorms/tt-norms-medium.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TTNorms';
  font-display: fallback;
  src: url('/themes/sky/fonts/TTNorms/tt-norms-regular.eot');
  src: url('/themes/sky/fonts/TTNorms/tt-norms-regular.eot?#iefix') format('embedded-opentype'),
       url('/themes/sky/fonts/TTNorms/tt-norms-regular.woff2') format('woff2'),
       url('/themes/sky/fonts/TTNorms/tt-norms-regular.woff') format('woff'),
       url('/themes/sky/fonts/TTNorms/tt-norms-regular.ttf') format('truetype'),
       url('/themes/sky/fonts/TTNorms/tt-norms-regular.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TTNorms-Extra-Bold';
  font-display: fallback;
  src: url('/themes/sky/fonts/TTNorms/tt-norms-extra-bold.otf') format('opentype');
}

@font-face {
  font-family: 'TTNorms-Bold';
  font-display: fallback;
  src: url('/themes/sky/fonts/TTNorms/TTNorms-Bold.otf') format('opentype');
}
