@import (reference) "colors.less";
@import (reference) "screen-sizes.less";

.alert {
  .font-primary-bold;
  border: 1px solid transparent;
  padding: 13px 44px 13px 10px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  font-size: 18px;

  &.alert-success {
    background-color: @color-alert-success-bg;
    border-color: @color-alert-success-bg;
    color: @color-alert-success-text;
    a{
      color: @color-alert-success-text;
      text-decoration: underline;
      &:hover {
        color: @color-alert-success-text;
      }
    }
  }
  &.alert-warning {
    background-color: @color-alert-warning-bg;
    border-color: @color-alert-warning-bg;
    color: @color-text-primary;
    a{
      color: @color-text-primary;
      text-decoration: underline;
      &:hover {
        color: @color-text-primary;
      }
    }
  }
  &.alert-error {
    background-color: @color-alert-error-bg;
    border-color: @color-alert-error-bg;
    color: @color-white;
    a{
      color: @color-white;
      text-decoration: underline;
      &:hover {
        color: @color-white;
      }
    }
  }
  &.alert-notice {
    background-color: @color-alert-notice-bg;
    border-color: @color-alert-notice-bg;
    color: @color-alert-notice-text;

    a{
      color: @color-alert-notice-text;
      text-decoration: underline;
      &:hover {
        color: @color-alert-notice-text;
      }
    }
  }
  i {
    font-size: 24px;
    margin-right: 2px;
  }

  // Close icon
  .icon-cancel-light,  .icon-cancel {
    position: absolute;
    height: 18px;
    width: 18px;
    font-size: 18px;
    line-height: 24px;
    top: 50%;
    margin-top: -12px;
    right: 3%;
  }
}
