@import (reference) "colors.less";
@import (reference) "screen-sizes.less";
@import (reference) "layers.less";
@import (reference) "typography.less";

#modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(46, 68, 83);
  background: rgba(46, 68, 83, .85);
  z-index: 60000000;
  display: none;

  html.ie8 & {
    -ms-filter: "alpha(opacity=70)";
  }
}

.modal,
.react-modal {
  .typography-small;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  top: -100%;
  max-height: 90%;
  left: 50%;
  margin-left: -295px;
  width: 550px;
  z-index: 60000005;
  transition: opacity .3s, top .3s;

  .bg-overlay {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 60000003;
    background: rgba(0, 0, 0, .6);
  }

  &.open {
    opacity: 1;
    pointer-events: auto;
    top: 8%;

    &.has_overlay {
      .overlay {
        display: block;
      }
    }

    .bg-overlay {
      display: block;
    }
  }

  &.small {
    width: 400px;
    margin-left: -200px;

    @media (max-width: (850px)) {
      margin-left: -45%;
      width: 90%;
    }
  }

  &.large {
    width: 850px;
    margin-left: -425px;

    @media (max-width: (850px)) {
      margin-left: -45%;
      width: 90%;
    }
  }

  &.fullscreen {
    width: 1024px;
    margin-left: -512px;

    @media (max-width: (1024px)) {
      margin-left: -48%;
      width: 96%;
    }
  }

  img {
    max-width: 100%;
  }

  .icon-cancel-light,
  .icon-cancel {
    border-radius: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
    font-size: 14px;
    color: @color-dark-gray87;
    margin: 0;
    width: 36px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    z-index: 60000010;
    transition: 250ms background;
    outline: none;

    &::before {
      margin-left: .3em;
      content: '\e8c7';
    }

    &:hover {
      background: rgba(86, 114, 196, 0.1);
    }
  }

  .overlay {
    display: none;
    position: fixed;
    z-index: 60000010;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding-top: 150px;
    background: #000;
    background: rgba(0, 0, 0, .7);
    text-align: center;
    color: white;
    font-size: 45px;
  }

  .modal-content {
    .layer-modal;
    position: relative;
    background: #fff;
    min-height: 300px;
    max-height: ~"calc(100vh - 50px)";
    color: @color-gray-primary;
    box-sizing: border-box;
    padding: 50px 60px 60px;
    overflow: auto;
    text-align: left;
    z-index: 60000008;

    h3 {
      margin-bottom: 25px;
      color: @color-text-primary;
      text-align: left;
    }

    .actions {
      margin-top: 30px;
      text-align: center;

      .btn,
      a {
        margin: 15px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .buttons {
      text-align: right;

      @media (max-width: (@screen-phone)) {
        display: flex;
        flex-direction: column-reverse;
        text-align: center;

        .btn {
          width: unset !important;
          margin: 10px 0 0 !important;
        }
      }
    }

    @media (max-width: (@screen-tablet)) {
      padding: 40px 20px;
    }

    @media (max-width: (@screen-phone)) {
      padding: 30px 10px;
    }
  }

  .add-another-school {
    margin-bottom: 30px;
  }

  .school-name-hidden {
    display: none;
  }

  @media (max-width: (611px)) {
    margin-left: -49.5%;
    width: 96%;
  }
}
